import styled from '@emotion/styled'
import { Box, BoxProps, Divider } from '@mui/material'
import { usePathname, useRouter } from 'next/navigation'
import { useCallback } from 'react'

import { ChatIcon } from '@/components/icon/ChatIcon'
import { AnnouncementIcon } from '@/components/icon/AnnouncementIcon'
import { ApplicationLogo } from '@/components/icon/application-logo'
import { LogoutIcon } from '@/components/icon/LogoutIcon'
import { SettingIcon } from '@/components/icon/SettingIcon'
import {
  bgColor,
  functionalColor,
  mainColor,
  outlineColor,
} from '@/utils/themeConfigs/customTheme'
import { mediaQuery } from '@/utils/helpers/breakpoint'
import { useIsMobileOrTablet } from '@/utils/hooks/useIsMobileOrTablet'
import { useAuthContext } from '@/context/AuthContext'
import { useDisclosure } from '@/utils/hooks/useDisclosure'

import { NavigationBarItem } from './NavigationBarItem'
import { LogoutModal } from './LogoutModal'

const Container = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isAdmin' && prop !== 'isManagementPortal',
})<ContainerProps>`
  height: 100%;
  width: 88px;
  background-color: ${({ isManagementPortal }) =>
    isManagementPortal ? mainColor.admin : bgColor.dark};
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: ${({ isAdmin }) =>
    isAdmin ? '78.9px 68px 68px 1fr 68px 1px 68px' : '78.9px 68px 68px 1fr 68px'};
  gap: 24px;
  place-items: center;
  padding: 32px 0 32px 0;
  ${mediaQuery('tab')} {
    position: fixed;
    z-index: 1000;
    top: auto;
    bottom: 0;
    height: 64px;
    max-width: 100%;
    width: 100%;
    grid-template-rows: 1;
    grid-template-columns: repeat(3, 1fr);
    padding: 8px;
    border-radius: 12px 12px 0px 0px;
    align-items: start;
    justify-content: center;
  }
`

const Hr = styled(Divider)`
  border-color: ${outlineColor.white};
  width: 36px;
  opacity: 50%;
`

const ItemContainer = styled(Box)`
  height: 68px;
  width: 68px;
  ${mediaQuery('tab')} {
    height: 48px;
    width: 68px;
  }
`

const ImageContainer = styled(Box)`
  grid-row: 1;
  grid-column: 1;
`

const UnreadBadge = styled(Box)<{ isCurrentPage: boolean }>`
  position: relative;

  &::before {
    position: absolute;
    display: block;
    content: '';
    width: 13px;
    height: 13px;
    top: 0px;
    left: -6px;
    background-color: ${functionalColor.red};
    border-radius: 50%;
    background-clip: content-box;
    border: ${({ isCurrentPage }) =>
      isCurrentPage ? '2px solid rgba(159,74,72)' : '2px solid rgba(132, 24, 24)'};
  }
`

type Props = {
  isAdmin?: boolean
  isManagementPortal?: boolean
}

type ContainerProps = BoxProps & {
  isAdmin?: boolean
  isManagementPortal?: boolean
}

export const NavigationBar = ({ isAdmin = false, isManagementPortal = false }: Props) => {
  const isMobileOrTablet = useIsMobileOrTablet()
  const modal = useDisclosure()
  const router = useRouter()
  const pathName = usePathname()
  const { user } = useAuthContext()

  const toChatPage = useCallback(() => {
    if (pathName !== '/') {
      router.push('/')
    }
  }, [router, pathName])

  const toInformationPage = useCallback(() => {
    if (!pathName.includes('information')) {
      router.push('/information')
    }
  }, [router, pathName])

  const toAdminPage = useCallback(() => {
    if (!pathName.includes('admin')) {
      router.push('/admin/users/students')
    }
  }, [router, pathName])

  return (
    <Container isAdmin={isAdmin} isManagementPortal={isManagementPortal}>
      {!isMobileOrTablet && (
        <ImageContainer>
          <ApplicationLogo />
        </ImageContainer>
      )}
      <ItemContainer onClick={toChatPage}>
        <NavigationBarItem icon={<ChatIcon />} disabled={pathName === '/'}>
          CHAT
        </NavigationBarItem>
      </ItemContainer>
      <ItemContainer
        onClick={!pathName.includes('information') ? toInformationPage : undefined}
      >
        <NavigationBarItem
          icon={
            user?.infoFlag ? (
              <UnreadBadge isCurrentPage={pathName.includes('information')}>
                <AnnouncementIcon />
              </UnreadBadge>
            ) : (
              <AnnouncementIcon />
            )
          }
          disabled={pathName.includes('information')}
        >
          INFO
        </NavigationBarItem>
      </ItemContainer>
      {!isMobileOrTablet && <Box />}
      <ItemContainer onClick={modal.handleOpen}>
        <NavigationBarItem icon={<LogoutIcon />}>LOGOUT</NavigationBarItem>
      </ItemContainer>
      <LogoutModal modal={modal} />
      {!isMobileOrTablet && isAdmin && (
        <>
          <Hr />
          <ItemContainer onClick={!pathName.includes('admin') ? toAdminPage : undefined}>
            <NavigationBarItem
              icon={<SettingIcon />}
              disabled={pathName.includes('admin')}
            >
              管理者
              <br />
              ページ
            </NavigationBarItem>
          </ItemContainer>
        </>
      )}
    </Container>
  )
}
