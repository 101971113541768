export const ChatIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
    >
      <path
        d='M13.617 21.1641C16.1669 21.1641 18.4796 20.1066 20.1203 18.4067C20.397 18.1201 20.3674 17.6654 20.0511 17.4282C20.0511 17.4282 16.0582 16.2719 13.3008 12.4668'
        stroke='white'
        strokeWidth='2.03046'
        strokeMiterlimit='10'
      />
      <path
        d='M12.1758 11.696C16.8407 12.9117 22.1283 12.1408 22.1283 12.1408C22.405 12.1408 22.6422 11.9036 22.6225 11.6269C22.3556 6.88285 18.422 3.10742 13.6089 3.10742'
        stroke='white'
        strokeWidth='2.03046'
        strokeMiterlimit='10'
      />
      <path
        d='M18.0204 8.47935C18.061 7.60695 17.5813 6.87582 16.9488 6.84632C16.3163 6.81683 15.7706 7.50013 15.7299 8.37253C15.6892 9.24493 16.169 9.97606 16.8015 10.0056C17.434 10.0351 17.9797 9.35174 18.0204 8.47935Z'
        fill='white'
      />
      <path
        d='M11.9657 14.5423C10.0681 14.908 8.42744 13.2674 8.78324 11.3599C8.98091 10.3024 9.85064 9.44253 10.9082 9.23498C12.8058 8.8693 14.4464 10.5099 14.0906 12.4174C13.8929 13.4749 13.0232 14.3348 11.9657 14.5423Z'
        fill='white'
      />
      <path
        d='M11.2548 11.6863C11.1066 11.6863 10.9583 11.5973 10.899 11.4491L8.53689 5.91442C7.88459 4.24413 8.18109 2.80117 9.34732 1.84248C10.5729 0.834385 12.342 0.844268 13.5477 1.87213C14.7041 2.85058 14.9808 4.29355 14.3088 5.95395L12.1641 11.459C12.085 11.6566 11.8577 11.7456 11.66 11.6665C11.4624 11.5875 11.3734 11.3601 11.4525 11.1625L13.5972 5.65745C13.9727 4.72842 14.1803 3.40405 13.0536 2.45525C12.1344 1.67447 10.7804 1.66458 9.84149 2.43548C8.69502 3.3744 8.89269 4.68888 9.25837 5.6278L11.6205 11.1625C11.6996 11.3601 11.6106 11.5875 11.4129 11.6665C11.3635 11.6863 11.3141 11.6962 11.2647 11.6962L11.2548 11.6863Z'
        fill='white'
        stroke='white'
        strokeWidth='0.761421'
        strokeMiterlimit='10'
      />
      <path
        d='M11.0465 11.8937C11.0465 11.8937 10.9476 11.8937 10.8982 11.864L5.15601 9.70947C3.51537 8.98798 2.70494 7.76245 2.85319 6.2503C3.01132 4.66897 4.26651 3.42367 5.84784 3.29518C7.35999 3.1667 8.57564 3.9969 9.27736 5.64742C9.35642 5.84508 9.27736 6.0724 9.06981 6.15147C8.87214 6.23053 8.64482 6.15147 8.56576 5.94392C8.17042 5.01488 7.38964 3.9376 5.90714 4.06608C4.70137 4.16492 3.74269 5.11372 3.62409 6.32937C3.47584 7.80198 4.54324 8.60253 5.46239 8.99787L11.2046 11.1524C11.4023 11.2315 11.4912 11.4588 11.4122 11.6565C11.3529 11.8047 11.2046 11.8937 11.0564 11.8937H11.0465Z'
        fill='white'
        stroke='white'
        strokeWidth='0.761421'
        strokeMiterlimit='10'
      />
      <path
        d='M3.7932 15.1352C2.82463 15.1352 1.99443 14.7497 1.37178 13.9788C0.363681 12.7533 0.373565 10.9842 1.40143 9.77841C2.37988 8.62206 3.83273 8.34533 5.48325 9.0174C5.68091 9.09646 5.77975 9.32378 5.70068 9.52145C5.62161 9.71911 5.3943 9.81795 5.19663 9.73888C3.83273 9.1953 2.75545 9.3732 1.99443 10.2825C1.21365 11.2016 1.20376 12.5556 1.97466 13.4945C2.91358 14.641 4.22806 14.4433 5.16698 14.0777L10.8993 11.6859C11.097 11.6068 11.3243 11.6958 11.4034 11.8934C11.4824 12.0911 11.3935 12.3184 11.1958 12.3975L5.4536 14.7893C4.87048 15.0166 4.31701 15.1253 3.80308 15.1253L3.7932 15.1352Z'
        fill='white'
        stroke='white'
        strokeWidth='0.761421'
        strokeMiterlimit='10'
      />
      <path
        d='M6.14602 20.5019C6.02742 20.5019 5.9187 20.5019 5.8001 20.4821C4.21877 20.324 2.97347 19.0688 2.84499 17.4973C2.7165 15.9852 3.5467 14.7695 5.19722 14.0678C5.39489 13.9888 5.6222 14.0678 5.70127 14.2754C5.78034 14.473 5.70127 14.7004 5.49372 14.7794C4.1397 15.3527 3.50717 16.252 3.61589 17.438C3.71472 18.6438 4.66352 19.6025 5.87917 19.7211C7.36167 19.8693 8.15234 18.8019 8.54767 17.8828L11.0679 12.1406C11.147 11.9429 11.3743 11.854 11.572 11.933C11.7696 12.0121 11.8586 12.2394 11.7795 12.4371L9.25927 18.1892C8.59709 19.7013 7.50004 20.5118 6.14602 20.5118V20.5019Z'
        fill='white'
        stroke='white'
        strokeWidth='0.761421'
        strokeMiterlimit='10'
      />
      <path
        d='M11.4315 22.706C10.6804 22.706 9.92927 22.449 9.3165 21.9351C8.16015 20.9566 7.88342 19.5137 8.55548 17.8533C8.63455 17.6556 8.86187 17.5568 9.05953 17.6358C9.2572 17.7149 9.35603 17.9422 9.27697 18.1399C8.73338 19.5038 8.91128 20.5811 9.82055 21.3421C10.7397 22.1229 12.0937 22.1327 13.0326 21.3618C14.1791 20.4229 13.9814 19.1084 13.6158 18.1695L11.4612 12.4273C11.3821 12.2296 11.4711 12.0023 11.6687 11.9233C11.8664 11.8442 12.0937 11.9331 12.1728 12.1308L14.3273 17.8829C14.9796 19.5532 14.6831 20.9962 13.5169 21.9647C12.9041 22.4688 12.1728 22.7159 11.4315 22.7159V22.706Z'
        fill='white'
        stroke='white'
        strokeWidth='0.761421'
        strokeMiterlimit='10'
      />
      <path
        d='M11.2574 11.6864L8.83594 3.89832L11.3364 1.69434L13.9061 2.93964L14.0346 5.84534L11.2574 11.6864Z'
        fill='white'
        stroke='white'
        strokeWidth='0.761421'
        strokeMiterlimit='10'
      />
    </svg>
  )
}
