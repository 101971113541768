export const LogoutIcon = () => {
  return (
    <svg
      width='20'
      height='21'
      viewBox='0 0 20 21'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M14 15.5L19 10.5M19 10.5L14 5.5M19 10.5H7M7 1.5H5.8C4.11984 1.5 3.27976 1.5 2.63803 1.82698C2.07354 2.1146 1.6146 2.57354 1.32698 3.13803C1 3.77976 1 4.61984 1 6.3V14.7C1 16.3802 1 17.2202 1.32698 17.862C1.6146 18.4265 2.07354 18.8854 2.63803 19.173C3.27976 19.5 4.11984 19.5 5.8 19.5H7'
        stroke='white'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
