export const ApplicationLogo = ({ size = 72 }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 72 72'
      fill='none'
    >
      <g clipPath='url(#clip0_4731_9307)'>
        <rect width='72' height='72' rx='6.80721' fill='#F5F8FA' />
        <path
          d='M0 13.7647L71.9401 13.7637'
          stroke='#8C0026'
          strokeWidth='1.1176'
          strokeMiterlimit='10'
        />
        <path
          d='M0 10.8184L71.9401 10.8174'
          stroke='#8C0026'
          strokeWidth='1.1176'
          strokeMiterlimit='10'
        />
        <path
          d='M0 7.87211L71.9401 7.87109'
          stroke='#8C0026'
          strokeWidth='1.1176'
          strokeMiterlimit='10'
        />
        <path
          d='M8.44531 0.0302734L8.44633 71.9703'
          stroke='#8C0026'
          strokeWidth='1.1176'
          strokeMiterlimit='10'
        />
        <path
          d='M11.3926 0.0302734L11.3936 71.9703'
          stroke='#8C0026'
          strokeWidth='1.1176'
          strokeMiterlimit='10'
        />
        <path
          d='M14.3379 0.0292969L14.3389 71.9704'
          stroke='#8C0026'
          strokeWidth='1.1176'
          strokeMiterlimit='10'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M72 56.083H6.02155C2.69819 56.083 0 58.7892 0 62.1224V65.2808C0 68.9915 3.00812 71.9996 6.71882 71.9996H65.2812C68.9919 71.9996 72 68.9915 72 65.2808V56.083Z'
          fill='#333333'
        />
        <path
          d='M8.69109 61.9358C9.13102 62.1583 9.51405 62.455 9.84019 62.8258C10.1663 63.1977 10.4203 63.6325 10.6042 64.1304C10.7871 64.6282 10.8785 65.1524 10.8785 65.7041C10.8785 66.2558 10.7871 66.7984 10.6042 67.2962C10.4213 67.7941 10.1673 68.2289 9.84019 68.6008C9.51405 68.9726 9.13102 69.2662 8.69109 69.4827C8.25116 69.698 7.77466 69.8068 7.26056 69.8068C6.74646 69.8068 6.26996 69.6991 5.83003 69.4827C5.3901 69.2673 5.00707 68.9726 4.68093 68.6008C4.3548 68.2289 4.09978 67.7941 3.9169 67.2962C3.73402 66.7984 3.64258 66.268 3.64258 65.7041C3.64258 65.1403 3.73402 64.6282 3.9169 64.1304C4.09978 63.6325 4.3548 63.1977 4.68093 62.8258C5.00707 62.4539 5.3901 62.1573 5.83003 61.9358C6.26996 61.7143 6.74646 61.6025 7.26056 61.6025C7.77466 61.6025 8.25116 61.7133 8.69109 61.9358ZM8.1648 69.2398C8.38223 69.0183 8.54276 68.7267 8.64639 68.3671C8.75002 68.0074 8.81403 67.5939 8.83943 67.1255C8.86381 66.6582 8.876 66.1837 8.876 65.7041C8.876 65.2246 8.86381 64.7542 8.83943 64.2919C8.81504 63.8306 8.75002 63.4191 8.64639 63.0595C8.54276 62.6998 8.38223 62.4092 8.1648 62.1867C7.94738 61.9653 7.64563 61.8535 7.26056 61.8535C6.8755 61.8535 6.57374 61.9642 6.35632 62.1867C6.1389 62.4092 5.97837 62.6998 5.87473 63.0595C5.7711 63.4191 5.70608 63.8306 5.68169 64.2919C5.65731 64.7542 5.64512 65.2246 5.64512 65.7041C5.64512 66.1837 5.65731 66.6571 5.68169 67.1255C5.70608 67.5929 5.7711 68.0074 5.87473 68.3671C5.97837 68.7267 6.1389 69.0173 6.35632 69.2398C6.57374 69.4623 6.8755 69.5731 7.26056 69.5731C7.64563 69.5731 7.94738 69.4623 8.1648 69.2398Z'
          fill='white'
        />
        <path
          d='M13.7769 61.7468C13.9303 61.7854 14.0806 61.8047 14.229 61.8047C14.3773 61.8047 14.5277 61.7854 14.6811 61.7468C14.8345 61.7082 14.9849 61.6604 15.1332 61.6035V63.4567H16.8675V63.6579H15.1332V68.4707C15.1332 68.6139 15.1434 68.7491 15.1627 68.873C15.182 68.998 15.2196 69.1097 15.2734 69.2103C15.3273 69.3109 15.4065 69.3902 15.5112 69.4471C15.6148 69.505 15.751 69.5334 15.9186 69.5334C16.1066 69.5334 16.2671 69.4948 16.4002 69.4186C16.5333 69.3424 16.6501 69.2438 16.7487 69.124C16.8472 69.0041 16.9285 68.8679 16.9935 68.7145C17.0575 68.5611 17.1094 68.4128 17.149 68.2695L17.3715 68.3122C17.3126 68.6383 17.211 68.8994 17.0677 69.0955C16.9244 69.2916 16.7609 69.443 16.578 69.5476C16.3951 69.6533 16.2 69.7224 15.9928 69.7559C15.7855 69.7894 15.5874 69.8057 15.3994 69.8057C14.8853 69.8057 14.4881 69.7478 14.2056 69.633C13.9242 69.5182 13.7159 69.3739 13.5828 69.2022C13.4497 69.0295 13.3725 68.8425 13.3532 68.6424C13.3329 68.4412 13.3237 68.2543 13.3237 68.0826V63.6579H12.3301V63.4567H13.3237V61.6035C13.4721 61.6614 13.6224 61.7092 13.7758 61.7468H13.7769Z'
          fill='white'
        />
        <path
          d='M23.3102 61.9358C23.7502 62.1583 24.1332 62.455 24.4593 62.8258C24.7855 63.1977 25.0395 63.6325 25.2234 64.1304C25.4062 64.6282 25.4977 65.1524 25.4977 65.7041C25.4977 66.2558 25.4062 66.7984 25.2234 67.2962C25.0405 67.7941 24.7865 68.2289 24.4593 68.6008C24.1332 68.9726 23.7502 69.2662 23.3102 69.4827C22.8703 69.698 22.3938 69.8068 21.8797 69.8068C21.3656 69.8068 20.8891 69.6991 20.4492 69.4827C20.0092 69.2673 19.6262 68.9726 19.3001 68.6008C18.9739 68.2289 18.7189 67.7941 18.536 67.2962C18.3532 66.7984 18.2617 66.268 18.2617 65.7041C18.2617 65.1403 18.3532 64.6282 18.536 64.1304C18.7189 63.6325 18.9739 63.1977 19.3001 62.8258C19.6262 62.4539 20.0092 62.1573 20.4492 61.9358C20.8891 61.7143 21.3656 61.6025 21.8797 61.6025C22.3938 61.6025 22.8703 61.7133 23.3102 61.9358ZM22.7839 69.2398C23.0014 69.0183 23.1619 68.7267 23.2655 68.3671C23.3692 68.0074 23.4332 67.5939 23.4586 67.1255C23.483 66.6582 23.4951 66.1837 23.4951 65.7041C23.4951 65.2246 23.483 64.7542 23.4586 64.2919C23.4342 63.8306 23.3692 63.4191 23.2655 63.0595C23.1619 62.6998 23.0014 62.4092 22.7839 62.1867C22.5665 61.9653 22.2648 61.8535 21.8797 61.8535C21.4946 61.8535 21.1929 61.9642 20.9755 62.1867C20.758 62.4092 20.5975 62.6998 20.4939 63.0595C20.3902 63.4191 20.3252 63.8306 20.3008 64.2919C20.2765 64.7542 20.2643 65.2246 20.2643 65.7041C20.2643 66.1837 20.2765 66.6571 20.3008 67.1255C20.3252 67.5929 20.3902 68.0074 20.4939 68.3671C20.5975 68.7267 20.758 69.0173 20.9755 69.2398C21.1929 69.4623 21.4946 69.5731 21.8797 69.5731C22.2648 69.5731 22.5665 69.4623 22.7839 69.2398Z'
          fill='white'
        />
        <path
          d='M29.8267 61.854V63.1311H29.8562C29.9151 63.0112 29.9944 62.87 30.0929 62.7084C30.1915 62.5469 30.3225 62.3904 30.4861 62.2411C30.6497 62.0917 30.8468 61.9627 31.0795 61.854C31.3111 61.7463 31.5905 61.6924 31.9177 61.6924C32.2631 61.6924 32.5527 61.7371 32.7853 61.8275C33.017 61.918 33.21 62.0318 33.3634 62.1689C33.5168 62.3071 33.6398 62.4595 33.7343 62.6271C33.8277 62.7948 33.9141 62.9634 33.9933 63.1311C34.1417 62.807 34.3073 62.553 34.4902 62.366C34.6731 62.1801 34.858 62.0358 35.0459 61.9342C35.2339 61.8326 35.4117 61.7666 35.5793 61.7361C35.747 61.7066 35.8851 61.6914 35.9949 61.6914C36.5588 61.6914 36.9926 61.793 37.2994 61.9972C37.6052 62.2014 37.8277 62.4443 37.9669 62.7257C38.1051 63.0071 38.1864 63.3048 38.2118 63.6157C38.2362 63.9276 38.2484 64.1918 38.2484 64.4072V69.3185H39.2268V69.5705H35.5651V69.3185H36.4399V63.7417C36.4399 63.2378 36.3637 62.8517 36.2103 62.5814C36.0568 62.3112 35.8028 62.1771 35.4462 62.1771C35.3375 62.1771 35.2034 62.2126 35.0459 62.2847C34.8874 62.3569 34.735 62.4646 34.5867 62.6089C34.4384 62.7531 34.3124 62.9421 34.2087 63.1758C34.1051 63.4095 34.0533 63.6889 34.0533 64.012V69.3185H34.9281V69.5705H31.3548V69.3185H32.2448V63.5974C32.2448 63.0701 32.1503 62.7013 31.9634 62.491C31.7754 62.2817 31.5682 62.176 31.3406 62.176C30.9453 62.176 30.5989 62.3863 30.3022 62.806C30.223 62.9258 30.1539 63.0488 30.0949 63.1748C30.036 63.3008 29.9883 63.445 29.9537 63.6066C29.9192 63.7681 29.8948 63.9551 29.8796 64.1644C29.8643 64.3747 29.8572 64.6297 29.8572 64.9294V69.3185H30.732V69.5705H27.0703V69.3185H28.0487V62.1049H27.0703V61.8529H29.8277L29.8267 61.854Z'
          fill='white'
        />
        <path
          d='M45.6247 61.9358C46.0646 62.1583 46.4476 62.455 46.7738 62.8258C47.0999 63.1977 47.3539 63.6325 47.5378 64.1304C47.7207 64.6282 47.8121 65.1524 47.8121 65.7041C47.8121 66.2558 47.7207 66.7984 47.5378 67.2962C47.3549 67.7941 47.1009 68.2289 46.7738 68.6008C46.4476 68.9726 46.0646 69.2662 45.6247 69.4827C45.1848 69.698 44.7083 69.8068 44.1942 69.8068C43.6801 69.8068 43.2036 69.6991 42.7636 69.4827C42.3237 69.2673 41.9407 68.9726 41.6145 68.6008C41.2884 68.2289 41.0334 67.7941 40.8505 67.2962C40.6676 66.7984 40.5762 66.268 40.5762 65.7041C40.5762 65.1403 40.6676 64.6282 40.8505 64.1304C41.0334 63.6325 41.2884 63.1977 41.6145 62.8258C41.9407 62.4539 42.3237 62.1573 42.7636 61.9358C43.2036 61.7143 43.6801 61.6025 44.1942 61.6025C44.7083 61.6025 45.1848 61.7133 45.6247 61.9358ZM45.0984 69.2398C45.3158 69.0183 45.4763 68.7267 45.58 68.3671C45.6836 68.0074 45.7476 67.5939 45.773 67.1255C45.7974 66.6582 45.8096 66.1837 45.8096 65.7041C45.8096 65.2246 45.7974 64.7542 45.773 64.2919C45.7486 63.8306 45.6836 63.4191 45.58 63.0595C45.4763 62.6998 45.3158 62.4092 45.0984 62.1867C44.881 61.9653 44.5792 61.8535 44.1942 61.8535C43.8091 61.8535 43.5073 61.9642 43.2899 62.1867C43.0725 62.4092 42.912 62.6998 42.8083 63.0595C42.7047 63.4191 42.6397 63.8306 42.6153 64.2919C42.5909 64.7542 42.5787 65.2246 42.5787 65.7041C42.5787 66.1837 42.5909 66.6571 42.6153 67.1255C42.6397 67.5929 42.7047 68.0074 42.8083 68.3671C42.912 68.7267 43.0725 69.0173 43.2899 69.2398C43.5073 69.4623 43.8091 69.5731 44.1942 69.5731C44.5792 69.5731 44.881 69.4623 45.0984 69.2398Z'
          fill='white'
        />
        <path
          d='M50.7085 61.7468C50.8619 61.7854 51.0123 61.8047 51.1606 61.8047C51.309 61.8047 51.4593 61.7854 51.6127 61.7468C51.7662 61.7082 51.9165 61.6604 52.0649 61.6035V63.4567H53.7992V63.6579H52.0649V68.4707C52.0649 68.6139 52.075 68.7491 52.0943 68.873C52.1136 68.998 52.1512 69.1097 52.2051 69.2103C52.2589 69.3109 52.3382 69.3902 52.4428 69.4471C52.5465 69.505 52.6826 69.5334 52.8502 69.5334C53.0382 69.5334 53.1987 69.4948 53.3318 69.4186C53.4649 69.3424 53.5818 69.2438 53.6803 69.124C53.7789 69.0041 53.8601 68.8679 53.9252 68.7145C53.9892 68.5611 54.041 68.4128 54.0806 68.2695L54.3031 68.3122C54.2442 68.6383 54.1426 68.8994 53.9993 69.0955C53.8561 69.2916 53.6925 69.443 53.5096 69.5476C53.3267 69.6533 53.1317 69.7224 52.9244 69.7559C52.7171 69.7894 52.519 69.8057 52.3311 69.8057C51.817 69.8057 51.4197 69.7478 51.1373 69.633C50.8558 69.5182 50.6475 69.3739 50.5144 69.2022C50.3814 69.0295 50.3041 68.8425 50.2848 68.6424C50.2645 68.4412 50.2554 68.2543 50.2554 68.0826V63.6579H49.2617V63.4567H50.2554V61.6035C50.4037 61.6614 50.5541 61.7092 50.7075 61.7468H50.7085Z'
          fill='white'
        />
        <path
          d='M56.6402 61.7468C56.7936 61.7854 56.9439 61.8047 57.0923 61.8047C57.2406 61.8047 57.391 61.7854 57.5444 61.7468C57.6978 61.7082 57.8482 61.6604 57.9965 61.6035V63.4567H59.7308V63.6579H57.9965V68.4707C57.9965 68.6139 58.0067 68.7491 58.026 68.873C58.0453 68.998 58.0829 69.1097 58.1367 69.2103C58.1906 69.3109 58.2698 69.3902 58.3745 69.4471C58.4781 69.505 58.6142 69.5334 58.7819 69.5334C58.9698 69.5334 59.1304 69.4948 59.2635 69.4186C59.3966 69.3424 59.5134 69.2438 59.612 69.124C59.7105 69.0041 59.7918 68.8679 59.8568 68.7145C59.9208 68.5611 59.9726 68.4128 60.0123 68.2695L60.2348 68.3122C60.1758 68.6383 60.0742 68.8994 59.931 69.0955C59.7877 69.2916 59.6242 69.443 59.4413 69.5476C59.2584 69.6533 59.0633 69.7224 58.8561 69.7559C58.6488 69.7894 58.4507 69.8057 58.2627 69.8057C57.7486 69.8057 57.3514 69.7478 57.0689 69.633C56.7875 69.5182 56.5792 69.3739 56.4461 69.2022C56.313 69.0295 56.2358 68.8425 56.2165 68.6424C56.1962 68.4412 56.187 68.2543 56.187 68.0826V63.6579H55.1934V63.4567H56.187V61.6035C56.3353 61.6614 56.4857 61.7092 56.6391 61.7468H56.6402Z'
          fill='white'
        />
        <path
          d='M66.1735 61.9358C66.6134 62.1583 66.9965 62.455 67.3226 62.8258C67.6487 63.1977 67.9028 63.6325 68.0866 64.1304C68.2695 64.6282 68.361 65.1524 68.361 65.7041C68.361 66.2558 68.2695 66.7984 68.0866 67.2962C67.9038 67.7941 67.6498 68.2289 67.3226 68.6008C66.9965 68.9726 66.6134 69.2662 66.1735 69.4827C65.7336 69.698 65.2571 69.8068 64.743 69.8068C64.2289 69.8068 63.7524 69.6991 63.3125 69.4827C62.8725 69.2673 62.4895 68.9726 62.1634 68.6008C61.8372 68.2289 61.5822 67.7941 61.3993 67.2962C61.2164 66.7984 61.125 66.268 61.125 65.7041C61.125 65.1403 61.2164 64.6282 61.3993 64.1304C61.5822 63.6325 61.8372 63.1977 62.1634 62.8258C62.4895 62.4539 62.8725 62.1573 63.3125 61.9358C63.7524 61.7143 64.2289 61.6025 64.743 61.6025C65.2571 61.6025 65.7336 61.7133 66.1735 61.9358ZM65.6472 69.2398C65.8647 69.0183 66.0252 68.7267 66.1288 68.3671C66.2324 68.0074 66.2965 67.5939 66.3219 67.1255C66.3462 66.6582 66.3584 66.1837 66.3584 65.7041C66.3584 65.2246 66.3462 64.7542 66.3219 64.2919C66.2975 63.8306 66.2324 63.4191 66.1288 63.0595C66.0252 62.6998 65.8647 62.4092 65.6472 62.1867C65.4298 61.9653 65.128 61.8535 64.743 61.8535C64.3579 61.8535 64.0562 61.9642 63.8387 62.1867C63.6213 62.4092 63.4608 62.6998 63.3572 63.0595C63.2535 63.4191 63.1885 63.8306 63.1641 64.2919C63.1397 64.7542 63.1275 65.2246 63.1275 65.7041C63.1275 66.1837 63.1397 66.6571 63.1641 67.1255C63.1885 67.5929 63.2535 68.0074 63.3572 68.3671C63.4608 68.7267 63.6213 69.0173 63.8387 69.2398C64.0562 69.4623 64.3579 69.5731 64.743 69.5731C65.128 69.5731 65.4298 69.4623 65.6472 69.2398Z'
          fill='white'
        />
        <path
          d='M30.4372 35.3087C33.3365 35.3087 35.6869 31.9066 35.6869 27.71C35.6869 23.5134 33.3365 20.1113 30.4372 20.1113C27.5379 20.1113 25.1875 23.5134 25.1875 27.71C25.1875 31.9066 27.5379 35.3087 30.4372 35.3087Z'
          fill='#333333'
        />
        <path
          d='M32.3784 26.8674C33.2324 26.8674 33.9247 25.7848 33.9247 24.4493C33.9247 23.1139 33.2324 22.0312 32.3784 22.0312C31.5244 22.0312 30.832 23.1139 30.832 24.4493C30.832 25.7848 31.5244 26.8674 32.3784 26.8674Z'
          fill='white'
        />
        <path
          d='M53.113 35.3087C56.0123 35.3087 58.3626 31.9066 58.3626 27.71C58.3626 23.5134 56.0123 20.1113 53.113 20.1113C50.2136 20.1113 47.8633 23.5134 47.8633 27.71C47.8633 31.9066 50.2136 35.3087 53.113 35.3087Z'
          fill='#333333'
        />
        <path
          d='M55.0542 26.8674C55.9082 26.8674 56.6005 25.7848 56.6005 24.4493C56.6005 23.1139 55.9082 22.0312 55.0542 22.0312C54.2001 22.0312 53.5078 23.1139 53.5078 24.4493C53.5078 25.7848 54.2001 26.8674 55.0542 26.8674Z'
          fill='white'
        />
        <path
          d='M62.7752 45.669C61.6068 44.6226 59.6246 44.1583 57.5438 44.5951C54.5506 45.224 52.4902 47.4796 52.9433 49.6335C53.3955 51.7874 56.1895 53.0239 59.1826 52.395C61.9543 51.8128 63.9243 49.8357 63.842 47.8362C67.2436 45.4222 70.0914 40.9863 71.9416 35.3333V26.9746C71.3055 36.3797 67.4122 42.8537 62.7762 45.6701L62.7752 45.669Z'
          fill='#333333'
        />
        <path
          d='M47.5777 38.9316C48.108 38.9316 48.5266 39.3817 48.488 39.9111C48.1324 44.733 45.1758 48.4963 41.5802 48.4963C37.9846 48.4963 35.028 44.734 34.6724 39.9111C34.6338 39.3827 35.0524 38.9316 35.5827 38.9316H47.5787H47.5777Z'
          stroke='#333333'
          strokeWidth='1.1176'
          strokeMiterlimit='10'
        />
        <path d='M71.9114 53.541H0.03125V56.081H71.9114V53.541Z' fill='white' />
        <path
          d='M42.9616 59.6249C43.4879 60.3086 44.5923 60.3198 45.1419 59.6513C46.9016 57.5116 50.0949 56.0811 53.7424 56.0811H36.6055C39.3284 56.5698 41.6154 57.8753 42.9616 59.6249Z'
          fill='white'
        />
      </g>
      <defs>
        <clipPath id='clip0_4731_9307'>
          <rect width='72' height='72' rx='6.80721' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}
