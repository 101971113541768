import { useEffect, useState } from 'react'

import { breakpoints } from '@/utils/constants/breakpoints'

export const useIsMobileOrTablet = (): boolean => {
  const [isTablet, setIsTablet] = useState(false)

  useEffect(() => {
    if (typeof window !== 'undefined') {
      // クライアントサイドでのみ実行
      const updateSize = (): void => {
        const width = window.innerWidth
        setIsTablet(width <= breakpoints.tab)
      }

      window.addEventListener('resize', updateSize)
      updateSize() // 初期状態を設定

      return () => window.removeEventListener('resize', updateSize)
    }
  }, [])

  return isTablet
}
