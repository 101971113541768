export const AnnouncementIcon = () => {
  return (
    <svg
      width='22'
      height='21'
      viewBox='0 0 22 21'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M17.5 14.9041C19.433 14.9041 21 11.994 21 8.40411C21 4.81426 19.433 1.90411 17.5 1.90411M17.5 14.9041C15.567 14.9041 14 11.994 14 8.40411C14 4.81426 15.567 1.90411 17.5 1.90411M17.5 14.9041L4.44354 12.5302C3.51605 12.3616 3.05231 12.2773 2.67733 12.0931C1.91447 11.7183 1.34636 11.0376 1.11414 10.22C1 9.81816 1 9.34681 1 8.40411C1 7.46141 1 6.99007 1.11414 6.58818C1.34636 5.7706 1.91447 5.08988 2.67733 4.71516C3.05231 4.53097 3.51605 4.44665 4.44354 4.27801L17.5 1.90411M4 12.9041L4.39386 18.4181C4.43126 18.9418 4.44996 19.2036 4.56387 19.402C4.66417 19.5767 4.81489 19.717 4.99629 19.8046C5.20232 19.9041 5.46481 19.9041 5.98979 19.9041H7.7722C8.37234 19.9041 8.67242 19.9041 8.89451 19.7844C9.08966 19.6793 9.24434 19.5122 9.33423 19.3096C9.43653 19.079 9.41351 18.7798 9.36748 18.1814L9 13.4041'
        stroke='white'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
