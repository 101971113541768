import styled from '@emotion/styled'
import { Box, Button, Typography } from '@mui/material'
import { ReactNode } from 'react'

import { functionalColor, textColor, fontWeight } from '@/utils/themeConfigs/customTheme'
import { mediaQuery } from '@/utils/helpers/breakpoint'

const Item = styled(Button)`
  color: ${textColor.white};
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 4px;
  width: 100%;
  height: 100%;
  opacity: 50%;
  &:disabled {
    background-color: ${functionalColor.translucentWhite};
    opacity: 100%;
  }
`

const IconContainer = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
`

const ItemText = styled(Typography)`
  font-weight: ${fontWeight.bold};
  font-size: 0.75rem;
  line-height: 1.58;
  color: ${textColor.white};
  width: 60px;
  white-space: pre-wrap;
  word-break: keep-all;
  ${mediaQuery('tab')} {
    font-size: 0.625rem;
  }
`

type Props = {
  icon: JSX.Element
  disabled?: boolean
  children?: ReactNode
}

export const NavigationBarItem = ({ icon, disabled = false, children }: Props) => {
  return (
    <Item disabled={disabled}>
      <IconContainer>{icon}</IconContainer>
      <ItemText align='center'>{children}</ItemText>
    </Item>
  )
}
