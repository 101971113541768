import styled from '@emotion/styled'

import { BasicModal } from '@/components/uis/Modal/BasicModal'
import { mediaQuery } from '@/utils/helpers/breakpoint'
import { useAuthentication } from '@/utils/hooks/useAuthentication'
import { UesDisclosure } from '@/utils/hooks/useDisclosure'
import { BasicButton } from '@/components/uis/Button/BasicButton'

import { Box } from '@mui/material'

type LogoutModalProps = {
  modal: UesDisclosure
}

export const LogoutModal = ({ modal }: LogoutModalProps) => {
  const { logout } = useAuthentication()

  const handleLogout = async () => {
    await logout()
  }

  const Modal = styled(BasicModal)`
    ${mediaQuery('tab')} {
      width: 343px;
    }
  `

  const Inner = styled(Box)`
    width: 100%;
    margin: 42px 0;
    text-align: center;
  `

  const Text = styled('span')`
    font-size: 20px;
    font-weight: 700;
  `

  const ItemContainer = styled('div')`
    ${mediaQuery('tab')} {
      padding: 32px 0 0;
    }
    display: flex;
    gap: 16px;
    padding: 32px 47px 0 47px;
  `

  return (
    <>
      <Modal open={modal.isOpen} onClose={modal.handleClose}>
        <Inner>
          <Text>ログアウトしますか</Text>
          <ItemContainer>
            <BasicButton
              onClick={modal.handleClose}
              label='キャンセル'
              variant='outlined'
              width='140px'
              height='47px'
            />
            <BasicButton
              onClick={() => {
                void (async () => {
                  await handleLogout()
                })()
              }}
              label='ログアウト'
              width='140px'
              height='47px'
            />
          </ItemContainer>
        </Inner>
      </Modal>
    </>
  )
}
