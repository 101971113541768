export const dateConverter = (
  value: string,
  format: 'short' | 'long' = 'short',
  breakChar = '/',
  isContainTime?: boolean,
) => {
  const date = new Date(value)
  const year =
    format === 'long'
      ? date.getFullYear().toString()
      : date.getFullYear().toString().slice(2)
  const month = (date.getMonth() + 1).toString().padStart(2, '0')
  const day = date.getDate().toString().padStart(2, '0')

  if (isContainTime) {
    const hour = date.getHours().toString().padStart(2, '0')
    const minute = date.getMinutes().toString().padStart(2, '0')
    return `${year}${breakChar}${month}${breakChar}${day} ${hour}:${minute}`
  }

  return `${year}${breakChar}${month}${breakChar}${day}`
}
