import styled from '@emotion/styled'
import { Box } from '@mui/material'

import { bgColor } from '@/utils/themeConfigs/customTheme'

const Container = styled(Box)`
  z-index: 999;
  height: 100%;
  width: 320px;
  background-color: ${bgColor.lightYellowGreen};
  display: flex;
  flex-direction: column;
`

type Props = {
  children: React.ReactElement
}

export const SideMenu = ({ children }: Props) => {
  return <Container>{children}</Container>
}
